<template>
  <div v-if="!loading">
    <Card :title="title" :disabled="!editMode">
      <template #close>
        <a @click.prevent="$router.go(-1)">
          <v-btn icon>
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
        </a>
      </template>
      <template #actions>
        <v-btn
          v-permission="{
            permissions: ['educations-update'],
          }"
          @click="editMode = !editMode"
        >
          <span>{{ editMode ? "Görüntüle" : "Düzenle" }}</span>
        </v-btn>
      </template>
      <v-card flat class="pa-4">
        <v-form ref="form" v-model="isFormValid" lazy-validation>
          <h2 class="mb-4">Bilgiler</h2>
          <v-row>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="currentTraining.name"
                label="Eğitim İsmi"
                hide-details="auto"
                :rules="[
                  (v) => !!v || 'Eğitim ismi alanı boş bırakılamaz',
                  (v) =>
                    v?.length >= 3 ||
                    'Eğitim ismi alanı en az 3 karakterden oluşmalıdır',
                ]"
                required
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="6">
              <v-text-field
                v-model="currentTraining.price"
                label="Baz Fiyat"
                hide-details="auto"
                :rules="priceRules"
                append-icon="mdi-currency-try"
                required
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <div class="d-flex justify-content-end">
                <v-btn class="mr-3" color="error" @click="reset"> İptal </v-btn>
                <v-btn
                  class="mr-3"
                  color="primary"
                  :disabled="!isFormValid"
                  @click="save"
                >
                  Kaydet
                </v-btn>
                <v-btn
                  color="primary"
                  :disabled="!isFormValid"
                  @click="saveAndClose"
                >
                  Kaydet ve Kapat
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </v-form>
      </v-card>
    </Card>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import {
  FETCH_TRAININGS,
  UPDATE_TRAINING,
  CREATE_TRAINING,
  GET_TRAINING,
} from "@/core/services/store/definitions/trainings.module";

import { currencyMask } from "@/core/helper";

import Card from "@/view/content/components/Card";

export default {
  components: { Card },
  data() {
    return {
      editMode: false,
      currentTraining: {},
      currencyMask,
      loading: !!this.$route.params.id,
      isFormValid: true,
      priceRules: [],
    };
  },
  mounted() {
    if (this.$route.params.id) {
      this.getTraining(this.$route.params.id)
        .then((data) => {
          this.currentTraining = Object.assign({}, data);
        })
        .finally(() => {
          this.loading = false;
        });
    }
    this.$route.query.editable
      ? (this.editMode = true)
      : (this.editMode = false);
  },
  methods: {
    ...mapActions([
      FETCH_TRAININGS,
      UPDATE_TRAINING,
      CREATE_TRAINING,
      GET_TRAINING,
    ]),
    async save(replaceRoute = true) {
      this.priceRules = [
        (v) => !!v || "Eğitim baz fiyat alanı boş bırakılamaz",
      ];
      const valid = this.$refs.form.validate();
      if (!valid) {
        this.isFormValid = false;
        return;
      }
      if (this.$route.params.id) {
        try {
          const training = JSON.parse(JSON.stringify(this.currentTraining));
          const data = {
            ...training,
            price: parseInt(training.price.replace(/[^0-9]/g, "")),
            id: this.$route.params.id,
          };
          await this.updateTraining(data);
          this.$toast.success("Eğitim güncellendi", {
            position: "bottom-right",
            duration: 2000,
          });
        } catch (error) {
          this.stopExecution = true;
          throw this.$toast.error(error[0].detail, {
            position: "bottom-right",
            duration: 2000,
          });
        }
      } else {
        try {
          const data = {
            ...this.currentTraining,
            price: parseInt(this.currentTraining.price.replace(/[^0-9]/g, "")),
          };
          const training = await this.createTraining(data);
          if (replaceRoute) {
            this.$router.push({
              name: "def-trainings-edit",
              params: {
                id: training.id,
              },
              query: { editable: true },
            });
          }
          this.$toast.success("Eğitim yaratıldı", {
            position: "bottom-right",
            duration: 2000,
          });
        } catch (error) {
          this.stopExecution = true;
          throw this.$toast.error(error[0].detail, {
            position: "bottom-right",
            duration: 2000,
          });
        }
      }
    },
    async saveAndClose() {
      await this.save(false);
      if (this.isFormValid && !this.stopExecution)
        this.$router.push({ name: "def-trainings" });
      this.stopExecution = false;
    },
    reset() {
      if (this.$route.params.id) {
        this.currentTraining = Object.assign({}, this.training);
      } else {
        this.currentTraining = {};
      }
      this.priceRules = [];
      this.$router.push({ name: "def-trainings" });
    },
    goBack() {
      if (
        this.$router.history.current.path.includes("definitions/trainings/edit")
      ) {
        this.$router.push({ name: "def-trainings" });
      } else {
        this.$router.go(-1);
      }
    },
  },
  computed: {
    ...mapGetters(["trainings", "training"]),
    title() {
      if (this.$route.params.id) {
        if (this.editMode) return "Eğitim Düzenle";
        return "Eğitim Detay";
      }
      return "Eğitim Yaratma";
    },
  },
  watch: {
    "currentTraining.price"() {
      this.priceRules = [];
    },
  },
};
</script>

<style></style>
